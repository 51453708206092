img {
  max-width: 100%;
  height: auto;
}
body {
  background-image: url('./images/eight_horns.png');
  background-repeat: repeat;
}
#main {
  background-color: rgba(#fff, 0.8);
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Crete Round', serif;
}
html,
body {
  font-family: 'Roboto', Helvetica, sans-serif;
}
