#intro {
  background-color: #444;
  color: #fefefe;
  padding: 0 0 20px 0;
  position: relative;
  box-shadow: 0 0 5px 3px rgba(#000 ,0.5);
  #slogan {
    // H1 properties
    font-family: 'Crete Round', serif;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.1;
    // Positioning
    margin-top: 0;
    margin-bottom: 12.5px;
    position: absolute;
    bottom: 0;
    a {
      color: inherit;
    }
    .home & {
      top: 100%;
      bottom: auto;
      margin-top: -1em;
      span {
        top: 1em;
        color: #444;
      }
    }
    span {
      display: inline-block;
      position: relative;
      top: 0.25em;
      color: #ddd;
      font-size: 0.75em;
      line-height: 1.25em;
      @include media("<tablet") {
        font-size: 0.6em;
      }
    }
    @include media("<640px") {
      body:not(.home) & {
        bottom: 45px;
      }
    }
    @include media("<480px") {
      font-size: 25px;
      max-width: calc(100% - 136px);
      .home & {
        margin-top: -0.75em;
	span {
          top: 1.5em;
        }
      }
    }
  }
  > .container {
    padding-top: 240px;
    padding-left: 236px;
    position: relative;
    .home & {
      padding-top: 336px;
    }
    @include media("<tablet") {
      // From home default styling
      padding-top: 186px !important;
      padding-left: 168px;
    }
    @include media("<phone") {
      // From home default styling
      padding-top: 132px !important;
    }
    @include media("<640px") {
      padding-left: 136px;
    }
  }
  .social {
    position: absolute;
    top: 20px;
    right: 0;
    font-weight: bold;
    a {
      color: #fefefe;
      .fa-inverse {
        color: #444;
      }
    }
    @include media("<640px") {
     body:not(.home) & {
       position: static;
     }
    }
  }
}
#profile_picture {
  position: absolute;
  left: 20px;
  bottom: -118px;
  width: 196px;
  @include media("<tablet") {
    width: 128px;
    bottom: -84px;
  }
  @include media("<640px") {
    width: 96px;
    bottom: -68px;
  }
}
img.circle {
  border-radius: 50%;
  box-shadow: 2px 1px 7px 1px rgba(0,0,0,0.3)
}
.home #main {
  padding-top: 128px;
  padding-bottom: 32px;
  @include media("<tablet") {
    padding-top: 86px;
  }
}
.introduction {
  font-size: 1.25em;
}
#skills {
  h2 {
    text-align: center;
    padding-bottom: 4px;
    border-bottom: 2px solid darken(#f2f2f2, 10%);
  }
  ul {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.25em;
    li {
      margin-bottom: 5px;
    }
  }
  > div:nth-child(even) > ul {
    background-color: #f2f2f2;
  }
}
#recent_posts {
  h3 a {
    color: #666;
  }
}
