/*!
Theme Name:     Jeremy Hough
Theme URI:      http://www.jeremyhough.com/
Description:    Theme for JeremyHough.com
Author:         Jeremy Hough
Author URI:     http://www.jeremyhough.com/
Version:        1.0
Tags:
*/
@import 'variables';
@import 'bootstrap';
@import 'mixins';
/*
@import 'extends';
*/
@import 'base';
@import 'fonts';
/*
@import 'misc';
@import 'forms';

@import 'navigation';
@import 'posts';
*/
@import 'pages';
/*
@import 'sidebars';
@import 'footer';
*/
